import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import {
  isEmpty,
  formatMoney,
  isPositiveNumber,
  getSymbolCurrency,
} from "@helpers";
import { WalletActions, WalletGetters } from "@store/modules/wallet/types";
import { profileModule, userModule, walletModule } from "@store/namespaces";
import { Currency } from "@/types/currency";
import { VForm } from "@/types/vuetify";
import { ProfileGetters } from "@store/modules/profile/types";
import { UserActions, UserGetters } from "@store/modules/user/types";
import debounce from "lodash.debounce";
import { UserWebPreferencesKey } from "@/types/user";
import { SecondFactorCode } from "@/types/2fa";

@Component
export default class WalletTransferFunds extends Vue {
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @walletModule.Action("transferFundsWallet")
  private readonly transferFundsWalletAction!: WalletActions["transferFundsWallet"];
  @walletModule.Action("fetchWallets")
  private readonly fetchWalletsAction!: WalletActions["fetchWallets"];
  @profileModule.Getter
  private readonly profileSecondFactorEnabled!: ProfileGetters["profileSecondFactorEnabled"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: Boolean, default: false })
  private readonly disabledReverse!: boolean;
  @Prop({ type: String, required: true }) private readonly sender!: string;
  @Prop({ type: Boolean, default: false })
  private readonly senderIsTeamlead!: boolean;
  @Prop({ type: Object, required: true })
  private readonly senderBalance!: Record<Currency, number>;
  @Prop({ type: String, required: true }) private readonly recipient!: string;
  @Prop({ type: Object, required: true })
  private readonly recipientBalance!: Record<Currency, number>;
  @Prop({ type: Boolean, default: false })
  private readonly recipientIsTeamlead!: boolean;

  private amount = "";
  private loading = false;
  private isShowDelayTransferInfo = false;
  private awaitingConfirmation = false;
  private currency = Currency.EUR;
  private localSender = "";
  private localRecipient = "";
  private secondFactorCode: SecondFactorCode = "";

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
    };
  }

  private get localSenderIsTeamlead() {
    if (this.recipientIsTeamlead) {
      return this.localSender === this.recipient;
    }

    return this.senderIsTeamlead && this.localSender === this.sender;
  }

  private get localRecipientIsTeamlead() {
    if (this.localRecipient === this.sender) {
      return this.senderIsTeamlead;
    }

    return this.recipientIsTeamlead && this.localRecipient === this.recipient;
  }

  private get formatedSender() {
    if (this.localSenderIsTeamlead) {
      return this.$vuetify.lang.t("$vuetify.dashboard.my_balance");
    }

    return this.localSender;
  }

  private get formatedRecipient() {
    if (this.localRecipientIsTeamlead) {
      return this.$vuetify.lang.t("$vuetify.dashboard.my_balance");
    }

    return this.localRecipient;
  }

  private get title() {
    if (this.isShowDelayTransferInfo) {
      return this.$vuetify.lang.t(
        "$vuetify.info.transferred_5_minutes",
        formatMoney({
          value: this.amount,
          currency: this.currency,
        })
      );
    }

    if (!this.isShowTransferFundsForm) {
      return this.$vuetify.lang.t(
        "$vuetify.info.sure_want_refill_account",
        `<b>${this.localRecipient}</b>`,
        `<b clas="text-no-wrap">${formatMoney({
          value: +this.amount,
          currency: this.currency,
        })}</b>`
      );
    }

    return this.$vuetify.lang.t("$vuetify.dashboard.money_transaction");
  }

  private get validForm() {
    return (
      (!this.profileSecondFactorEnabled ||
        this.fieldRules.required(this.secondFactorCode) === true) &&
      !this.isInsufficientBalance &&
      +this.amount > 0
    );
  }

  private get isShowTransferFundsForm() {
    return !this.awaitingConfirmation;
  }

  private get currencyIcon() {
    return require(`@/assets/img/currency/flags/${this.currency}.svg`);
  }

  private get currencySymbol() {
    return getSymbolCurrency(this.currency);
  }

  private get currentBalance() {
    const balance =
      this.localSender === this.recipient
        ? this.recipientBalance
        : this.senderBalance;

    return balance[this.currency] ?? 0;
  }

  private get isInsufficientBalance() {
    return this.currentBalance <= 0 || this.currentBalance < +this.amount;
  }

  @Watch("sender", { immediate: true })
  @Watch("recipient")
  private onChangeSenderOrRecipient() {
    this.localSender = this.sender;
    this.localRecipient = this.recipient;
  }

  private reverseSenderAndRecipient() {
    const sender = this.localSender;
    const recipient = this.localRecipient;

    this.localSender = recipient;
    this.localRecipient = sender;
  }

  private onClickClose() {
    this.$emit("close");
  }

  private onClickCancel() {
    this.awaitingConfirmation = false;
  }

  private onSubmitForm() {
    if (!this.validForm || !this.formRef.validate()) return;

    this.awaitingConfirmation = true;
  }

  private async transferFundsWallet() {
    if (this.loading) return;

    this.loading = true;

    const sender = this.localSender;
    const recipient = this.localRecipient;

    try {
      const { status } = await this.transferFundsWalletAction({
        currency: this.currency,
        amount: +this.amount,
        from: sender,
        to: recipient,
        secondFactorCode: this.secondFactorCode,
      });

      this.loading = false;

      if (status === "SUCCESS") {
        this.$notify({
          type: "success",
          title: this.$vuetify.lang.t(
            "$vuetify.info.refill_user_success",
            recipient,
            formatMoney({
              value: +this.amount,
              currency: this.currency,
            })
          ),
        });

        this.fetchWalletsAction();
        this.onClickClose();
      } else {
        this.isShowDelayTransferInfo = true;
      }

      this.$emit("success");
    } catch (error) {
      this.awaitingConfirmation = false;
      this.loading = false;
    }
  }

  private async initWebPreferences() {
    this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.WALLET_TRANSFER_FUNDS,
    });

    this.$watch(
      () => {
        const { value } = this.userWebPreferencesGetter({
          key: UserWebPreferencesKey.WALLET_TRANSFER_FUNDS,
        });

        return value;
      },
      (value) => {
        const userWebPreferences = value as Partial<{
          currency: Currency;
        }>;

        this.currency = userWebPreferences.currency ?? this.currency;
      },
      { immediate: true }
    );

    return this.$watch(
      () => {
        return [this.currency].join("-");
      },
      debounce(() => {
        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.WALLET_TRANSFER_FUNDS,
          value: {
            currency: this.currency,
          },
        });
      }, 500)
    );
  }

  private mounted() {
    this.initWebPreferences();
  }
}
